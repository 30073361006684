import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const SecondPage = () => (
  <Layout>
    <section style={{ maxWidth: 1000, margin: `0 auto`, padding: 25 }}>
      <h1 class="p1">
        <span class="s1">
          <strong>Terms and Conditions</strong>
        </span>
      </h1>
      <p class="p1">
        <span class="s1">Last updated: May 11, 2021</span>
      </p>
      <p class="p1">
        <span class="s1">
          Please read these terms and conditions carefully before using Our
          Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Interpretation and Definitions</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Interpretation</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Definitions</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">For the purposes of these Terms and Conditions:</span>
      </p>
      <ul class="ul1">
        <li class="li2">
          <span class="s1">
            <strong>Application</strong> means the software program provided by
            the Company downloaded by You on any electronic device, named 4BAGR,
            and includes all other related services we provide to you through
            that program and on our website, 4bagr.app.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Application Store</strong> means the digital distribution
            service operated and developed by Apple Inc. (Apple App Store) or
            Google Inc. (Google Play Store) in which the Application has been
            downloaded.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            &quot;control&quot; means ownership of 50% or more of the shares,
            equity interest or other securities entitled to vote for election of
            directors or other managing authority.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Country</strong> refers to: Missouri, United States
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to 4BAGR, LLC, a Missouri limited liability
            company.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Content</strong> refers to content such as text, images, or
            other information that can be posted, uploaded, linked to or
            otherwise made available by You, regardless of the form of that
            content.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Feedback</strong> means feedback, innovations or suggestions
            sent by You regarding the attributes, performance or features of our
            Service.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Goods</strong> refer to the items offered for sale on the
            Service.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>In-app Purchase</strong> refers to the purchase of a
            product, item, service or Subscription made through the Application
            and subject to these Terms and Conditions and/or the Application
            Store&apos;s own terms and conditions.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Orders</strong> mean a request by You to purchase Goods from
            Us.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Service</strong> refers to the Application.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Subscriptions</strong> refer to the services or access to
            the Service offered on a subscription basis by the Company to You.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Terms and Conditions</strong> (also referred as
            &quot;Terms&quot;) mean these Terms and Conditions that form the
            entire agreement between You and the Company regarding the use of
            the Service.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Third-party Social Media Service</strong> means any services
            or content (including data, information, products or services)
            provided by a third-party that may be displayed, included or made
            available by the Service.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </span>
        </li>
      </ul>
      <p class="p1">
        <span class="s1">
          <strong>Acknowledgment</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          These are the Terms and Conditions governing the use of this Service
          and the agreement that operates between You and the Company. These
          Terms and Conditions set out the rights and obligations of all users
          regarding the use of the Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Your access to and use of the Service is conditioned on Your
          acceptance of and compliance with these Terms and Conditions. These
          Terms and Conditions apply to all visitors, users and others who
          access or use the Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          By accessing or using the Service You agree to be bound by these Terms
          and Conditions. If You disagree with any part of these Terms and
          Conditions then You may not access the Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You may not use the Services if you are under 13 years old
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Your access to and use of the Service is also conditioned on Your
          acceptance of and compliance with the Privacy Policy of the Company.
          Our Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your personal information when You
          use the Application or the Website and tells You about Your privacy
          rights and how the law protects You. Please read Our Privacy Policy
          carefully before using Our Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Placing Orders for Goods</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          By placing an Order for Goods through the Service, You warrant that
          You are legally capable of entering into binding contracts.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Your Information</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If You wish to place an Order for Goods available on the Service, You
          may be asked to supply certain information relevant to Your Order
          including, without limitation, Your name, Your email, Your phone
          number, Your credit card number, the expiration date of Your credit
          card, Your billing address, and Your shipping information.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You represent and warrant that: (i) You have the legal right to use
          any credit or debit card(s) or other payment method(s) in connection
          with any Order; and that (ii) the information You supply to us is
          true, correct and complete.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          By submitting such information, You grant us the right to charge your
          payment information the applicable fees approved by you and to provide
          the information to payment processing third parties for purposes of
          facilitating the completion of Your Order.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Order Cancellation</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          We reserve the right to refuse or cancel Your Order at any time for
          certain reasons including but not limited to:
        </span>
      </p>
      <ul class="ul1">
        <li class="li1">
          <span class="s1">Goods availability</span>
        </li>
        <li class="li1">
          <span class="s1">Errors in the description or prices for Goods</span>
        </li>
        <li class="li1">
          <span class="s1">Errors in Your Order</span>
        </li>
      </ul>
      <p class="p1">
        <span class="s1">
          We reserve the right to refuse or cancel Your Order if fraud or an
          unauthorized or illegal transaction is suspected.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You will not have any right to cancel an Order for the supply of any
          of the following Goods:
        </span>
      </p>
      <ul class="ul1">
        <li class="li1">
          <span class="s1">
            The supply of Goods made to Your specifications or clearly
            personalized.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            The supply of Goods which according to their nature are not suitable
            to be returned, deteriorate rapidly or where the date of expiry is
            over.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            The supply of Goods which are not suitable for return due to health
            protection or hygiene reasons and were unsealed after delivery.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            The supply of Goods which are, after delivery, according to their
            nature, inseparably mixed with other items.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            The supply of digital content which is not supplied on a tangible
            medium if the performance has begun with Your prior express consent
            and You have acknowledged Your loss of cancellation right.
          </span>
        </li>
      </ul>
      <p class="p1">
        <span class="s1">
          <strong>Availability, Errors and Inaccuracies</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          We are constantly updating Our offerings of Goods on the Service. The
          Goods available on Our Service may be mispriced, described
          inaccurately, or unavailable, and We may experience delays in updating
          information regarding our Goods on the Service and in Our advertising
          on other websites.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          We cannot and do not guarantee the accuracy or completeness of any
          information, including prices, product images, specifications,
          availability, and services. We reserve the right to change or update
          information and to correct errors, inaccuracies, or omissions at any
          time without prior notice.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Prices Policy</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The Company reserves the right to revise its prices at any time prior
          to accepting an Order.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The prices quoted may be revised by the Company subsequent to
          accepting an Order in the event of any occurrence affecting delivery
          caused by government action, variation in customs duties, increased
          shipping charges, higher foreign exchange costs and any other matter
          beyond the control of the Company. In that event, You will have the
          right to cancel Your Order.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Payments</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          All Goods purchased are subject to a one-time payment. Payment can be
          made through various payment methods we have available, such as Visa,
          MasterCard, Affinity Card, American Express cards or online payment
          methods (PayPal, for example).
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Payment cards (credit cards or debit cards) are subject to validation
          checks and authorization by Your card issuer. If we do not receive the
          required authorization, We will not be liable for any delay or
          non-delivery of Your Order.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Subscriptions</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Subscription period</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The Service or some parts of the Service are available only with a
          paid Subscription. You will be billed in advance on a recurring and
          periodic basis (such as daily, weekly, monthly or annually), depending
          on the type of Subscription plan you select when purchasing the
          Subscription.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          At the end of each period, Your Subscription will automatically renew
          under the exact same conditions unless You cancel it or the Company
          cancels it.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Subscription cancellations</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You may cancel Your Subscription renewal either through Your Account
          settings page or by emailing the company at phil@4bagr.app. You will
          not receive a refund for the fees You already paid for Your current
          Subscription period and You will be able to access the Service until
          the end of Your current Subscription period.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If the Subscription has been made through an In-app Purchase, You can
          cancel the renewal of Your Subscription with the Application Store.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Billing</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You shall provide the Company with accurate and complete billing
          information including full name, address, state, zip code, telephone
          number, and a valid payment method information.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Should automatic billing fail to occur for any reason, the Company
          will issue an electronic invoice indicating that you must proceed
          manually, within a certain deadline date, with the full payment
          corresponding to the billing period as indicated on the invoice.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If the Subscription has been made through an In-app Purchase, all
          billing is handled by the Application Store and is governed by the
          Application Store&apos;s own terms and conditions.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Fee Changes</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The Company, in its sole discretion and at any time, may modify the
          Subscription fees. Any Subscription fee change will become effective
          at the end of the then-current Subscription period.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The Company will provide You with reasonable prior notice of any
          change in Subscription fees to give You an opportunity to terminate
          Your Subscription before such change becomes effective.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Your continued use of the Service after the Subscription fee change
          comes into effect constitutes Your agreement to pay the modified
          Subscription fee amount.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Refunds</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Except when required by law, paid Subscription fees are
          non-refundable.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If the Subscription has been made through an In-app purchase, the
          Application Store&apos;s refund policy will apply. If You wish to
          request a refund, You may do so by contacting the Application Store
          directly.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>In-app Purchases</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The Application may include In-app Purchases that allow you to buy
          products, services or Subscriptions.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          More information about how you may be able to manage In-app Purchases
          using your Device may be set out in the Application Store&apos;s own
          terms and conditions or in your Device&apos;s Help settings.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          In-app Purchases can only be consumed within the Application. If you
          make a In-app Purchase, that In-app Purchase cannot be cancelled after
          you have initiated its download. In-app Purchases cannot be redeemed
          for cash or other consideration or otherwise transferred.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If any In-app Purchase is not successfully downloaded or does not work
          once it has been successfully downloaded, we will, after becoming
          aware of the fault or being notified to the fault by You, investigate
          the reason for the fault. We will act reasonably in deciding whether
          to provide You with a replacement In-app Purchase or issue You with a
          patch to repair the fault. In no event will We charge You to replace
          or repair the In-app Purchase. In the unlikely event that we are
          unable to replace or repair the relevant In-app Purchase or are unable
          to do so within a reasonable period of time and without significant
          inconvenience to You, We will authorize the Application Store to
          refund You an amount up to the cost of the relevant In-app Purchase.
          Alternatively, if You wish to request a refund, You may do so by
          contacting the Application Store directly.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You acknowledge and agree that all billing and transaction processes
          are handled by the Application Store from where you downloaded the
          Application and are governed by that Application Store&apos;s own
          terms and conditions.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If you have any payment related issues with In-app Purchases, then you
          need to contact the Application Store directly.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>User Accounts</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          When You create an account with Us, You must provide Us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of Your account on Our Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You are responsible for safeguarding the password that You use to
          access the Service and for any activities or actions under Your
          password, whether Your password is with Our Service or a Third-Party
          Social Media Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You agree not to disclose Your password to any third party. You must
          notify Us immediately upon becoming aware of any breach of security or
          unauthorized use of Your account.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than You
          without appropriate authorization, or a name that is otherwise
          offensive, vulgar or obscene.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Content</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Your Right to Post Content</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Our Service allows You to post Content. You are responsible for the
          Content that You post to the Service, including its legality,
          reliability, and appropriateness.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          By posting Content to the Service, You grant Us the right and license
          to use, modify, publicly perform, publicly display, reproduce, and
          distribute such Content on and through the Service. You retain any and
          all of Your rights to any Content You submit, post or display on or
          through the Service and You are responsible for protecting those
          rights. You agree that this license includes the right for Us to make
          Your Content available to other users of the Service, who may also use
          Your Content subject to these Terms.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You represent and warrant that: (i) the Content is Yours (You own it)
          or You have the right to use it and grant Us the rights and license as
          provided in these Terms, and (ii) the posting of Your Content on or
          through the Service does not violate the privacy rights, publicity
          rights, copyrights, contract rights or any other rights of any person.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Content Restrictions</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The Company is not responsible for the content of the Service&apos;s
          users. You expressly understand and agree that You are solely
          responsible for the Content and for all activity that occurs under
          your account, whether done so by You or any third person using Your
          account.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You may not transmit any Content that is unlawful, offensive,
          upsetting, intended to disgust, threatening, libelous, defamatory,
          obscene or otherwise objectionable. Examples of such objectionable
          Content include, but are not limited to, the following:
        </span>
      </p>
      <ul class="ul1">
        <li class="li1">
          <span class="s1">Unlawful or promoting unlawful activity.</span>
        </li>
        <li class="li1">
          <span class="s1">
            Defamatory, discriminatory, or mean-spirited content, including
            references or commentary about religion, race, sexual orientation,
            gender, national/ethnic origin, or other targeted groups.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            Spam, machine &ndash; or randomly &ndash; generated, constituting
            unauthorized or unsolicited advertising, chain letters, any other
            form of unauthorized solicitation, or any form of lottery or
            gambling.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            Containing or installing any viruses, worms, malware, trojan horses,
            or other content that is designed or intended to disrupt, damage, or
            limit the functioning of any software, hardware or
            telecommunications equipment or to damage or obtain unauthorized
            access to any data or other information of a third person.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            Infringing on any proprietary rights of any party, including patent,
            trademark, trade secret, copyright, right of publicity or other
            rights.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            Impersonating any person or entity including the Company and its
            employees or representatives.
          </span>
        </li>
        <li class="li1">
          <span class="s1">Violating the privacy of any third person.</span>
        </li>
        <li class="li1">
          <span class="s1">False information and features.</span>
        </li>
      </ul>
      <p class="p1">
        <span class="s1">
          The Company reserves the right, but not the obligation, to, in its
          sole discretion, determine whether or not any Content is appropriate
          and complies with this Terms, refuse or remove this Content. The
          Company further reserves the right to make formatting and edits and
          change the manner any Content. The Company can also limit or revoke
          the use of the Service if You post such objectionable Content. As the
          Company cannot control all content posted by users and/or third
          parties on the Service, you agree to use the Service at your own risk.
          You understand that by using the Service You may be exposed to content
          that You may find offensive, indecent, incorrect or objectionable, and
          You agree that under no circumstances will the Company be liable in
          any way for any content, including any errors or omissions in any
          content, or any loss or damage of any kind incurred as a result of
          your use of any content.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Content Backups</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Although regular backups of Content are performed, the Company does
          not guarantee there will be no loss or corruption of data.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Corrupt or invalid backup points may be caused by, without limitation,
          Content that is corrupted prior to being backed up or that changes
          during the time a backup is performed.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The Company will provide support and attempt to troubleshoot any known
          or discovered issues that may affect the backups of Content. But You
          acknowledge that the Company has no liability related to the integrity
          of Content or the failure to successfully restore Content to a usable
          state.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You agree to maintain a complete and accurate copy of any Content in a
          location independent of the Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Copyright Policy</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Intellectual Property Infringement</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          We respect the intellectual property rights of others. It is Our
          policy to respond to any claim that Content posted on the Service
          infringes a copyright or other intellectual property infringement of
          any person.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If You are a copyright owner, or authorized on behalf of one, and You
          believe that the copyrighted work has been copied in a way that
          constitutes copyright infringement that is taking place through the
          Service, You must submit Your notice in writing to the attention of
          our copyright agent via email at phil@4bagr.app and include in Your
          notice a detailed description of the alleged infringement.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You may be held accountable for damages (including costs and
          attorneys&apos; fees) for misrepresenting that any Content is
          infringing Your copyright.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>
            DMCA Notice and DMCA Procedure for Copyright Infringement Claims
          </strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You may submit a notification pursuant to the Digital Millennium
          Copyright Act (DMCA) by providing our Copyright Agent with the
          following information in writing (see 17 U.S.C 512(c)(3) for further
          detail):
        </span>
      </p>
      <ul class="ul1">
        <li class="li1">
          <span class="s1">
            An electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright&apos;s interest.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            A description of the copyrighted work that You claim has been
            infringed, including the URL (i.e., web page address) of the
            location where the copyrighted work exists or a copy of the
            copyrighted work.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            Identification of the URL or other specific location on the Service
            where the material that You claim is infringing is located.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            Your address, telephone number, and email address.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            A statement by You that You have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law.
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            A statement by You, made under penalty of perjury, that the above
            information in Your notice is accurate and that You are the
            copyright owner or authorized to act on the copyright owner&apos;s
            behalf.
          </span>
        </li>
      </ul>
      <p class="p1">
        <span class="s1">
          You can contact our copyright agent via email at phil@4bagr.app. Upon
          receipt of a notification, the Company will take whatever action, in
          its sole discretion, it deems appropriate, including removal of the
          challenged content from the Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Intellectual Property</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The Services (including all design, software, code, and other content
          on the Services) are either owned by Company, licensed to Company, or
          likely owned by another individual or business, and may be protected
          by copyright, trademark, patent, trade secret, or other intellectual
          property laws. Your use of the Services does not grant any rights to
          You other than the right to use the Services for their intended
          purpose as outlined in these Terms.
          <span class="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Our trademarks and trade dress may not be used in connection with any
          product or service without the prior written consent of the Company.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Your Feedback to Us</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You assign all rights, title and interest in any Feedback You provide
          the Company. If for any reason such assignment is ineffective, You
          agree to grant the Company a non-exclusive, perpetual, irrevocable,
          royalty free, worldwide right and license to use, reproduce, disclose,
          sub-license, distribute, modify and exploit such Feedback without
          restriction.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Links to Other Websites</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by the Company.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The Company has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third-party web
          sites or services. You further acknowledge and agree that the Company
          shall not be responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with
          the use of or reliance on any such content, goods or services
          available on or through any such web sites or services.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          We strongly advise You to read the terms and conditions and privacy
          policies of any third-party web sites or services that You visit.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Termination</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          We may terminate or suspend Your Account (and your other rights to use
          the Services with or without an account) immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if You breach these Terms and Conditions.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Upon termination, Your right to use the Service will cease
          immediately. If You wish to terminate Your Account, You may simply
          discontinue using the Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Warranties, Disclaimers, and Limitations of Liability</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>
            All references to &ldquo;Us&rdquo; (and similar words such as
            &ldquo;We&rdquo; and &ldquo;Our&rdquo;) in this section mean Company
            and Company&rsquo;s parents, subsidiaries, affiliates, and
            licensors, and the owners, members, directors, officers, employees,
            contractors, and agents, of all of them.
          </strong>
        </span>
      </p>
      <ul class="ul1">
        <li class="li1">
          <span class="s1">
            <strong>
              The Services are offered on an &ldquo;AS IS&rdquo; and &ldquo;AS
              AVAILABLE&rdquo; basis without any representations or warranties
              of any kind.
            </strong>
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>
              Without limitation, We do not represent or warrant that (a) the
              information on the Services is free from error; (b) the
              functionality of the Services will be uninterrupted, secure, or
              free of errors; (c) defects in the Services will be corrected; or
              (d) that the Services or the equipment the Services use are free
              of viruses.
            </strong>
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>
              To the fullest extent permitted by law, We disclaim all
              representations and warranties (express, implied, and statutory),
              including the implied warranties of merchantability, title,
              fitness for a particular purpose, accuracy of data, and
              non-infringement; and also all liability for identity theft and
              other misuse of Your identity or content.
            </strong>
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>
              We do not vet content submitted by users of Our Services and We do
              not represent or guarantee that any such content is truthful or
              accurate or that You will have any right to use that content.
            </strong>
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>
              We do not warrant, endorse, guarantee, or assume responsibility
              for, any product or service advertised or offered by a third party
              on the Services, or for any other websites or applications which
              are linked to or referenced in the Services. If You use or
              purchase any such products or services, or if You click on any
              such links, You do so at Your sole risk.
            </strong>
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>
              We will not be liable to You or any third party for any indirect,
              special, incidental, consequential, cover, or punitive damages
              (including lost profits or revenues, loss of data, loss of use, or
              costs of obtaining substitute goods or services), arising out of
              or in connection to the Services or any links provided on the
              Services.<span class="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>
              All limitations of liability in these Terms will apply regardless
              of whether You or the third party bases Your/its claim on
              contract, tort, strict liability, or any other legal theory, and
              whether We knew or should have known about the possibility of such
              damages.
            </strong>
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>
              All limitations of liability in these Terms will apply to the
              fullest extent permitted by law.
            </strong>
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>
              Subject to the limitations of liability in these Terms, Our
              liability to You or any third party will not exceed the amount You
              paid for the Services, or if the claim does not relate to a
              purchase, then $100.
            </strong>
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>
              Any cause of action or claim which You may have which arises out
              of or in connection to the Services must be brought (if at all)
              within one year after the cause of action or claim accrued.
              Otherwise, such cause of action or claim will be permanently
              barred.
            </strong>
          </span>
        </li>
        <li class="li1">
          <span class="s1">
            <strong>
              All of the foregoing limitations will apply even if a remedy fails
              of its essential purpose and to the fullest extent permitted by
              law.
            </strong>
          </span>
        </li>
      </ul>
      <p class="p1">
        <span class="s1">
          <strong>
            Some jurisdictions do not allow certain disclaimers, limitations of
            liability, and exclusions. To the extent such jurisdictions&rsquo;
            laws are applicable to Your use of the Services, such disclaimers,
            limitations of liability, and exclusions, will be limited to the
            extent permitted by the applicable law.
          </strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Indemnification</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You shall indemnify Company and Company&rsquo;s affiliates and the
          respective owners, directors, officers, managers, employees,
          contractors, and other representatives of each (the &ldquo;Protected
          Parties&rdquo;) against all reasonable expenses including
          attorneys&rsquo; fees, costs, and damages of every kind (the
          &ldquo;Losses&rdquo;) arising out of any suit, claim, investigation,
          or proceeding, related to Your use of the Services, Your breach of
          these Terms, Your submission of content that violates third party
          rights or applicable laws, or Your violation of applicable law, except
          to the extent that a Protected Party negligently or intentionally
          caused those Losses.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Notwithstanding the agreement to arbitrate above, either You or
          Company may assert a claim seeking injunctive or other equitable
          relief from the courts as necessary to stop unauthorized use or abuse
          of the Services or intellectual property infringement (for example,
          trademark, trade secret, copyright, or patent rights) without first
          engaging in arbitration.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Governing Law</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          The laws of the Missouri, USA, excluding its conflicts of law rules,
          shall govern this Terms and Your use of the Service. Your use of the
          Application may also be subject to other local, state, national, or
          international laws.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Disputes Resolution</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If You have any concern or dispute about the Service, You agree to
          first try to resolve the dispute informally by contacting the Company.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Waiver of Jury Trial</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You and Company each waive trial by jury in all actions, proceedings,
          or counterclaims brought by either party against the other on any
          matter arising out of or in any way connected to Company&rsquo;s
          provision of the Services, Your use of the Services, these Terms, or
          Company&rsquo;s Privacy Policy.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>For European Union (EU) Users</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If You are a European Union consumer, you will benefit from any
          mandatory provisions of the law of the country in which you are
          resident in.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>United States Federal Government End Use Provisions</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If You are a U.S. federal government end user, our Service is a
          &quot;Commercial Item&quot; as that term is defined at 48 C.F.R.
          &sect;2.101.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>United States Legal Compliance</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          You represent and warrant that (i) You are not located in a country
          that is subject to the United States government embargo, or that has
          been designated by the United States government as a &quot;terrorist
          supporting&quot; country, and (ii) You are not listed on any United
          States government list of prohibited or restricted parties.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Severability and Waiver</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Severability</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If any provision of these Terms is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Waiver</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under this Terms shall not effect
          a party&apos;s ability to exercise such right or require such
          performance at any time thereafter nor shall be the waiver of a breach
          constitute a waiver of any subsequent breach.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Translation Interpretation</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          These Terms and Conditions may have been translated if We have made
          them available to You on our Service. You agree that the original
          English text shall prevail in the case of a dispute.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Changes to These Terms and Conditions</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          We reserve the right, at Our sole discretion, to modify or replace
          these Terms at any time. If a revision is material We will make
          reasonable efforts to provide at least 30 days&apos; notice prior to
          any new terms taking effect. What constitutes a material change will
          be determined at Our sole discretion.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          By continuing to access or use Our Service after those revisions
          become effective, You agree to be bound by the revised terms. If You
          do not agree to the new terms, in whole or in part, please stop using
          the website and the Service.
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          <strong>Contact Us</strong>
        </span>
      </p>
      <p class="p1">
        <span class="s1">
          If you have any questions about these Terms and Conditions, You can
          contact us:
        </span>
      </p>
      <ul class="ul1">
        <li class="li1">
          <span class="s1">By email: phil@4bagr.app</span>
        </li>
      </ul>
    </section>
  </Layout>
);

export default SecondPage;
